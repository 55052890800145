var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventHandler, Collection, Property, Event, formatUnit, NotifyPropertyChanges } from '@syncfusion/ej2-base';
import { ChildProperty, addClass, removeClass, setStyleAttribute, attributes, getUniqueID, compile, getInstance, L10n } from '@syncfusion/ej2-base';
import { append, closest, isNullOrUndefined, remove, classList, Touch, KeyboardEvents } from '@syncfusion/ej2-base';
import { Button } from '@syncfusion/ej2-buttons';
// Constant variables
const CLS_CAROUSEL = 'e-carousel';
const CLS_ACTIVE = 'e-active';
const CLS_RTL = 'e-rtl';
const CLS_ITEMS = 'e-carousel-items';
const CLS_ITEM = 'e-carousel-item';
const CLS_PREVIOUS = 'e-previous';
const CLS_NEXT = 'e-next';
const CLS_PREV_ICON = 'e-previous-icon';
const CLS_NEXT_ICON = 'e-next-icon';
const CLS_NAVIGATORS = 'e-carousel-navigators';
const CLS_INDICATORS = 'e-carousel-indicators';
const CLS_INDICATOR_BARS = 'e-indicator-bars';
const CLS_INDICATOR_BAR = 'e-indicator-bar';
const CLS_INDICATOR = 'e-indicator';
const CLS_ICON = 'e-icons';
const CLS_PLAY_PAUSE = 'e-play-pause';
const CLS_PLAY_ICON = 'e-play-icon';
const CLS_PAUSE_ICON = 'e-pause-icon';
const CLS_PREV_BUTTON = 'e-previous-button';
const CLS_NEXT_BUTTON = 'e-next-button';
const CLS_PLAY_BUTTON = 'e-play-button';
const CLS_FLAT = 'e-flat';
const CLS_ROUND = 'e-round';
const CLS_HOVER_ARROWS = 'e-hover-arrows';
const CLS_HOVER = 'e-carousel-hover';
const CLS_TEMPLATE = 'e-template';
const CLS_SLIDE_ANIMATION = 'e-carousel-slide-animation';
const CLS_FADE_ANIMATION = 'e-carousel-fade-animation';
const CLS_CUSTOM_ANIMATION = 'e-carousel-custom-animation';
const CLS_ANIMATION_NONE = 'e-carousel-animation-none';
const CLS_PREV_SLIDE = 'e-prev';
const CLS_NEXT_SLIDE = 'e-next';
const CLS_TRANSITION_START = 'e-transition-start';
const CLS_TRANSITION_END = 'e-transition-end';
/** Specifies the carousel individual item. */
export class CarouselItem extends ChildProperty {
}
__decorate([
    Property()
], CarouselItem.prototype, "cssClass", void 0);
__decorate([
    Property()
], CarouselItem.prototype, "interval", void 0);
__decorate([
    Property()
], CarouselItem.prototype, "template", void 0);
__decorate([
    Property()
], CarouselItem.prototype, "htmlAttributes", void 0);
let Carousel = class Carousel extends Component {
    /**
     * Constructor for creating the Carousel widget
     *
     * @param {CarouselModel} options Accepts the carousel model properties to initiate the rendering
     * @param {string | HTMLElement} element Accepts the DOM element reference
     */
    constructor(options, element) {
        super(options, element);
    }
    getModuleName() {
        return CLS_CAROUSEL.replace('e-', '');
    }
    getPersistData() {
        return this.addOnPersist(['selectedIndex']);
    }
    preRender() {
        this.keyConfigs = {
            home: 'home',
            end: 'end',
            space: 'space',
            moveLeft: 'leftarrow',
            moveRight: 'rightarrow',
            moveUp: 'uparrow',
            moveDown: 'downarrow'
        };
        const defaultLocale = {
            nextSlide: 'Next slide',
            of: 'of',
            pauseSlideTransition: 'Pause slide transition',
            playSlideTransition: 'Play slide transition',
            previousSlide: 'Previous slide',
            slide: 'Slide',
            slideShow: 'Slide show'
        };
        this.localeObj = new L10n(this.getModuleName(), defaultLocale, this.locale);
    }
    render() {
        this.initialize();
        this.renderSlides();
        this.renderNavigators();
        this.renderPlayButton();
        this.renderIndicators();
        this.applyAnimation();
        this.wireEvents();
    }
    onPropertyChanged(newProp, oldProp) {
        let target;
        for (const prop of Object.keys(newProp)) {
            switch (prop) {
                case 'animationEffect':
                    this.applyAnimation();
                    break;
                case 'cssClass':
                    classList(this.element, [newProp.cssClass], [oldProp.cssClass]);
                    break;
                case 'selectedIndex':
                    this.setActiveSlide(this.selectedIndex, oldProp.selectedIndex > this.selectedIndex ? 'Previous' : 'Next');
                    this.autoSlide();
                    break;
                case 'htmlAttributes':
                    if (!isNullOrUndefined(this.htmlAttributes)) {
                        this.setHtmlAttributes(this.htmlAttributes, this.element);
                    }
                    break;
                case 'enableTouchSwipe':
                    if (!this.enableTouchSwipe && this.touchModule) {
                        this.touchModule.destroy();
                    }
                    if (this.element.querySelector(`.${CLS_ITEMS}`)) {
                        this.renderTouchActions();
                    }
                    break;
                case 'loop':
                    if (this.loop && isNullOrUndefined(this.autoSlideInterval)) {
                        this.applySlideInterval();
                    }
                    this.handleNavigatorsActions(this.selectedIndex);
                    break;
                case 'enableRtl':
                    if (this.enableRtl) {
                        addClass([this.element], CLS_RTL);
                    }
                    else {
                        removeClass([this.element], CLS_RTL);
                    }
                    break;
                case 'buttonsVisibility':
                    target = this.element.querySelector(`.${CLS_NAVIGATORS}`);
                    if (target) {
                        switch (this.buttonsVisibility) {
                            case 'Hidden':
                                this.resetTemplates(['previousButtonTemplate', 'nextButtonTemplate']);
                                remove(target);
                                break;
                            case 'VisibleOnHover':
                                addClass([].slice.call(target.childNodes), CLS_HOVER_ARROWS);
                                break;
                            case 'Visible':
                                removeClass([].slice.call(target.childNodes), CLS_HOVER_ARROWS);
                                break;
                        }
                    }
                    else {
                        this.renderNavigators();
                        this.renderPlayButton();
                    }
                    break;
                case 'width':
                    setStyleAttribute(this.element, { 'width': formatUnit(this.width) });
                    break;
                case 'height':
                    setStyleAttribute(this.element, { 'height': formatUnit(this.height) });
                    break;
                case 'autoPlay':
                    if (this.showPlayButton && isNullOrUndefined(this.playButtonTemplate)) {
                        this.playButtonClickHandler(null, true);
                    }
                    this.autoSlide();
                    break;
                case 'interval':
                    this.autoSlide();
                    break;
                case 'showIndicators':
                    target = this.element.querySelector(`.${CLS_INDICATORS}`);
                    if (!this.showIndicators && target) {
                        this.resetTemplates(['indicatorsTemplate']);
                        remove(target);
                    }
                    this.renderIndicators();
                    break;
                case 'showPlayButton':
                    target = this.element.querySelector(`.${CLS_PLAY_PAUSE}`);
                    if (!this.showPlayButton && target) {
                        remove(target);
                        this.resetTemplates(['playButtonTemplate']);
                    }
                    this.renderPlayButton();
                    break;
                case 'items':
                case 'dataSource':
                    target = this.element.querySelector(`.${CLS_ITEMS}`);
                    if (target) {
                        this.resetTemplates(['itemTemplate']);
                        remove(target);
                    }
                    this.renderSlides();
                    break;
            }
        }
    }
    initialize() {
        const carouselClasses = [];
        if (this.cssClass) {
            carouselClasses.push(this.cssClass);
        }
        if (this.enableRtl) {
            carouselClasses.push(CLS_RTL);
        }
        addClass([this.element], carouselClasses);
        setStyleAttribute(this.element, { 'width': formatUnit(this.width), 'height': formatUnit(this.height) });
        attributes(this.element, { 'tabindex': '0', 'aria-roledescription': 'carousel', 'aria-label': this.localeObj.getConstant('slideShow') });
        if (!isNullOrUndefined(this.htmlAttributes)) {
            this.setHtmlAttributes(this.htmlAttributes, this.element);
        }
    }
    renderSlides() {
        const itemsContainer = this.createElement('div', { className: CLS_ITEMS, attrs: { 'aria-live': this.autoPlay ? 'off' : 'polite' } });
        this.element.appendChild(itemsContainer);
        if (this.items.length > 0) {
            this.slideItems = this.items;
            this.items.forEach((item, index) => {
                this.renderSlide(item, item.template, index, itemsContainer);
            });
        }
        else if (this.dataSource.length > 0) {
            this.slideItems = this.dataSource;
            this.dataSource.forEach((item, index) => {
                this.renderSlide(item, this.itemTemplate, index, itemsContainer);
            });
        }
        this.renderTemplates();
        this.autoSlide();
        this.renderTouchActions();
        this.renderKeyboardActions();
    }
    renderSlide(item, itemTemplate, index, container) {
        const itemEle = this.createElement('div', {
            id: getUniqueID('carousel_item'),
            className: `${CLS_ITEM} ${item.cssClass ? item.cssClass : ''} ${this.selectedIndex === index ? CLS_ACTIVE : ''}`,
            attrs: {
                'aria-hidden': this.selectedIndex === index ? 'false' : 'true', 'data-index': index.toString(),
                'aria-role': 'group', 'aria-roledescription': 'slide'
            }
        });
        if (!isNullOrUndefined(item.htmlAttributes)) {
            this.setHtmlAttributes(item.htmlAttributes, itemEle);
        }
        const templateId = this.element.id + '_template';
        const template = this.templateParser(itemTemplate)(item, this, 'itemTemplate', templateId, false);
        append(template, itemEle);
        container.appendChild(itemEle);
    }
    renderNavigators() {
        if (this.buttonsVisibility === 'Hidden') {
            return;
        }
        const navigators = this.createElement('div', { className: CLS_NAVIGATORS });
        const itemsContainer = this.element.querySelector(`.${CLS_ITEMS}`);
        itemsContainer.insertAdjacentElement('afterend', navigators);
        this.renderNavigatorButton('Previous');
        this.renderNavigatorButton('Next');
        this.renderTemplates();
    }
    renderNavigatorButton(direction) {
        const buttonContainer = this.createElement('div', {
            className: (direction === 'Previous' ? CLS_PREVIOUS : CLS_NEXT) + ' ' + (this.buttonsVisibility === 'VisibleOnHover' ? CLS_HOVER_ARROWS : ''),
            attrs: { 'aria-label': this.localeObj.getConstant(direction === 'Previous' ? 'previousSlide' : 'nextSlide') }
        });
        if (direction === 'Previous' && this.previousButtonTemplate) {
            addClass([buttonContainer], CLS_TEMPLATE);
            const templateId = this.element.id + '_previousButtonTemplate';
            const template = this.templateParser(this.previousButtonTemplate)({ type: 'Previous' }, this, 'previousButtonTemplate', templateId, false);
            append(template, buttonContainer);
        }
        else if (direction === 'Next' && this.nextButtonTemplate) {
            addClass([buttonContainer], CLS_TEMPLATE);
            const templateId = this.element.id + '_nextButtonTemplate';
            const template = this.templateParser(this.nextButtonTemplate)({ type: 'Next' }, this, 'nextButtonTemplate', templateId, false);
            append(template, buttonContainer);
        }
        else {
            const button = this.createElement('button');
            const buttonObj = new Button({
                cssClass: CLS_FLAT + ' ' + CLS_ROUND + ' ' + (direction === 'Previous' ? CLS_PREV_BUTTON : CLS_NEXT_BUTTON),
                iconCss: CLS_ICON + ' ' + (direction === 'Previous' ? CLS_PREV_ICON : CLS_NEXT_ICON),
                enableRtl: this.enableRtl,
                disabled: !this.loop && this.selectedIndex === (direction === 'Previous' ? 0 : this.slideItems.length - 1)
            });
            buttonObj.appendTo(button);
            buttonContainer.appendChild(button);
        }
        this.element.querySelector('.' + CLS_NAVIGATORS).appendChild(buttonContainer);
        EventHandler.add(buttonContainer, 'click', this.navigatorClickHandler, this);
    }
    renderPlayButton() {
        if (this.buttonsVisibility === 'Hidden' || !this.showPlayButton) {
            return;
        }
        const playPauseWrap = this.createElement('div', {
            className: CLS_PLAY_PAUSE + ' ' + (this.buttonsVisibility === 'VisibleOnHover' ? CLS_HOVER_ARROWS : '')
        });
        if (this.playButtonTemplate) {
            addClass([playPauseWrap], CLS_TEMPLATE);
            const templateId = this.element.id + '_playButtonTemplate';
            const template = this.templateParser(this.playButtonTemplate)({}, this, 'playButtonTemplate', templateId, false);
            append(template, playPauseWrap);
        }
        else {
            const playButton = this.createElement('button', {
                attrs: { 'aria-label': this.localeObj.getConstant(this.autoPlay ? 'pauseSlideTransition' : 'playSlideTransition') }
            });
            const isLastSlide = this.selectedIndex === this.slideItems.length - 1 && !this.loop;
            const buttonObj = new Button({
                cssClass: CLS_FLAT + ' ' + CLS_ROUND + ' ' + CLS_PLAY_BUTTON,
                iconCss: CLS_ICON + ' ' + (this.autoPlay && !isLastSlide ? CLS_PAUSE_ICON : CLS_PLAY_ICON),
                isToggle: true,
                enableRtl: this.enableRtl
            });
            if (isLastSlide) {
                this.setProperties({ autoPlay: false }, true);
                playButton.setAttribute('aria-label', this.localeObj.getConstant('playSlideTransition'));
                const itemsContainer = this.element.querySelector(`.${CLS_ITEMS}`);
                itemsContainer.setAttribute('aria-live', 'polite');
            }
            buttonObj.appendTo(playButton);
            playPauseWrap.appendChild(playButton);
        }
        const navigators = this.element.querySelector(`.${CLS_NAVIGATORS}`);
        navigators.insertBefore(playPauseWrap, navigators.lastElementChild);
        this.renderTemplates();
        EventHandler.add(playPauseWrap, 'click', this.playButtonClickHandler, this);
    }
    renderIndicators() {
        if (!this.showIndicators) {
            return;
        }
        const indicatorWrap = this.createElement('div', { className: CLS_INDICATORS });
        const indicatorBars = this.createElement('div', { className: CLS_INDICATOR_BARS });
        indicatorWrap.appendChild(indicatorBars);
        if (this.slideItems) {
            this.slideItems.forEach((item, index) => {
                const indicatorBar = this.createElement('div', {
                    className: CLS_INDICATOR_BAR + ' ' + (this.selectedIndex === index ? CLS_ACTIVE : ''),
                    attrs: { 'data-index': index.toString(), 'aria-current': this.selectedIndex === index ? 'true' : 'false' }
                });
                if (this.indicatorsTemplate) {
                    addClass([indicatorBar], CLS_TEMPLATE);
                    const templateId = this.element.id + '_indicatorsTemplate';
                    const template = this.templateParser(this.indicatorsTemplate)({ index: index, selectedIndex: this.selectedIndex }, this, 'indicatorsTemplate', templateId, false);
                    append(template, indicatorBar);
                }
                else {
                    const indicator = this.createElement('button', { className: CLS_INDICATOR });
                    indicatorBar.appendChild(indicator);
                    indicator.appendChild(this.createElement('div', {
                        attrs: {
                            'aria-label': this.localeObj.getConstant('slide') + ' ' + (index + 1) + ' ' + this.localeObj.getConstant('of') + ' ' + this.slideItems.length
                        }
                    }));
                    const buttonObj = new Button({ cssClass: 'e-flat e-small' });
                    buttonObj.appendTo(indicator);
                }
                indicatorBars.appendChild(indicatorBar);
                EventHandler.add(indicatorBar, 'click', this.indicatorClickHandler, this);
            });
        }
        this.element.appendChild(indicatorWrap);
    }
    renderKeyboardActions() {
        this.keyModule = new KeyboardEvents(this.element, { keyAction: this.keyHandler.bind(this), keyConfigs: this.keyConfigs });
    }
    renderTouchActions() {
        if (!this.enableTouchSwipe) {
            return;
        }
        this.touchModule = new Touch(this.element, { swipe: this.swipeHandler.bind(this) });
    }
    applyAnimation() {
        removeClass([this.element], [CLS_CUSTOM_ANIMATION, CLS_FADE_ANIMATION, CLS_SLIDE_ANIMATION, CLS_ANIMATION_NONE]);
        switch (this.animationEffect) {
            case 'Slide':
                addClass([this.element], CLS_SLIDE_ANIMATION);
                break;
            case 'Fade':
                addClass([this.element], CLS_FADE_ANIMATION);
                break;
            case 'None':
                addClass([this.element], CLS_ANIMATION_NONE);
                break;
            case 'Custom':
                addClass([this.element], CLS_CUSTOM_ANIMATION);
                break;
        }
    }
    autoSlide() {
        this.resetSlideInterval();
        this.applySlideInterval();
    }
    autoSlideChange() {
        const activeSlide = this.element.querySelector(`.${CLS_ACTIVE}`);
        if (isNullOrUndefined(activeSlide)) {
            return;
        }
        const activeIndex = parseInt(activeSlide.dataset.index, 10);
        if (!this.loop && activeIndex === this.slideItems.length - 1) {
            this.resetSlideInterval();
        }
        else {
            const index = (activeIndex + 1) % this.slideItems.length;
            if (!this.element.classList.contains(CLS_HOVER)) {
                this.setActiveSlide(index, 'Next');
            }
            this.autoSlide();
        }
    }
    applySlideInterval() {
        if (!this.autoPlay || this.element.classList.contains(CLS_HOVER)) {
            return;
        }
        let itemInterval = this.interval;
        if (this.items.length > 0 && !isNullOrUndefined(this.items[this.selectedIndex].interval)) {
            itemInterval = this.items[this.selectedIndex].interval;
        }
        this.autoSlideInterval = setInterval(() => this.autoSlideChange(), itemInterval);
    }
    resetSlideInterval() {
        clearInterval(this.autoSlideInterval);
        this.autoSlideInterval = null;
    }
    getSlideIndex(direction) {
        let currentIndex = this.selectedIndex;
        if (direction === 'Previous') {
            currentIndex--;
            if (currentIndex < 0) {
                currentIndex = this.slideItems.length - 1;
            }
        }
        else {
            currentIndex++;
            if (currentIndex === this.slideItems.length) {
                currentIndex = 0;
            }
        }
        return currentIndex;
    }
    setActiveSlide(currentIndex, direction, isSwiped = false) {
        if (this.element.querySelectorAll(`.${CLS_ITEM}.${CLS_PREV_SLIDE},.${CLS_ITEM}.${CLS_NEXT_SLIDE}`).length > 0) {
            return;
        }
        const allSlides = [].slice.call(this.element.querySelectorAll(`.${CLS_ITEM}`));
        const activeSlide = this.element.querySelector(`.${CLS_ITEM}.${CLS_ACTIVE}`);
        if (isNullOrUndefined(activeSlide) && this.showIndicators) {
            const activeIndicator = this.element.querySelector(`.${CLS_INDICATOR_BAR}.${CLS_ACTIVE}`);
            const activeIndex = parseInt(activeIndicator.dataset.index, 10);
            addClass([allSlides[activeIndex]], CLS_ACTIVE);
            return;
        }
        else if (isNullOrUndefined(activeSlide)) {
            addClass([allSlides[currentIndex]], CLS_ACTIVE);
            return;
        }
        const activeIndex = parseInt(activeSlide.dataset.index, 10);
        const currentSlide = allSlides[currentIndex];
        const eventArgs = {
            currentIndex: activeIndex,
            nextIndex: currentIndex,
            currentSlide: activeSlide,
            nextSlide: currentSlide,
            slideDirection: direction,
            isSwiped: isSwiped,
            cancel: false
        };
        this.trigger('slideChanging', eventArgs, (args) => {
            if (args.cancel) {
                return;
            }
            this.setProperties({ selectedIndex: currentIndex }, true);
            attributes(args.currentSlide, { 'aria-hidden': 'true' });
            attributes(args.nextSlide, { 'aria-hidden': 'false' });
            const slideIndicators = [].slice.call(this.element.querySelectorAll(`.${CLS_INDICATOR_BAR}`));
            if (slideIndicators.length > 0) {
                attributes(slideIndicators[activeIndex], { 'aria-current': 'false' });
                attributes(slideIndicators[currentIndex], { 'aria-current': 'true' });
                removeClass(slideIndicators, CLS_ACTIVE);
                addClass([slideIndicators[currentIndex]], CLS_ACTIVE);
            }
            this.slideChangedEventArgs = {
                currentIndex: args.nextIndex,
                previousIndex: args.currentIndex,
                currentSlide: args.nextSlide,
                previousSlide: args.currentSlide,
                slideDirection: direction,
                isSwiped: isSwiped
            };
            let slideHeight;
            if (this.animationEffect === 'Slide') {
                if (direction === 'Previous') {
                    addClass([args.nextSlide], CLS_PREV_SLIDE);
                    slideHeight = args.nextSlide.offsetHeight;
                    addClass([args.currentSlide, args.nextSlide], CLS_TRANSITION_END);
                }
                else {
                    addClass([args.nextSlide], CLS_NEXT_SLIDE);
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    slideHeight = args.nextSlide.offsetHeight;
                    addClass([args.currentSlide, args.nextSlide], CLS_TRANSITION_START);
                }
            }
            else if (this.animationEffect === 'Fade') {
                removeClass([args.currentSlide], CLS_ACTIVE);
                addClass([args.nextSlide], CLS_ACTIVE);
            }
            else if (this.animationEffect === 'Custom') {
                if (direction === 'Previous') {
                    addClass([args.nextSlide], CLS_NEXT_SLIDE);
                    addClass([args.currentSlide], CLS_PREV_SLIDE);
                }
                else {
                    addClass([args.currentSlide], CLS_PREV_SLIDE);
                    addClass([args.nextSlide], CLS_NEXT_SLIDE);
                }
            }
            else {
                this.onTransitionEnd();
            }
            this.handleNavigatorsActions(currentIndex);
        });
    }
    onTransitionEnd() {
        if (this.slideChangedEventArgs) {
            addClass([this.slideChangedEventArgs.currentSlide], CLS_ACTIVE);
            removeClass([this.slideChangedEventArgs.previousSlide], CLS_ACTIVE);
            this.trigger('slideChanged', this.slideChangedEventArgs, () => {
                removeClass(this.element.querySelectorAll(`.${CLS_ITEM}`), [CLS_PREV_SLIDE, CLS_NEXT_SLIDE, CLS_TRANSITION_START, CLS_TRANSITION_END]);
                this.slideChangedEventArgs = null;
            });
        }
    }
    setHtmlAttributes(attribute, element) {
        const keys = Object.keys(attribute);
        for (const key of keys) {
            if (key === 'class') {
                addClass([element], attribute[key]);
            }
            else {
                element.setAttribute(key, attribute[key]);
            }
        }
    }
    templateParser(template) {
        if (template) {
            try {
                if (document.querySelectorAll(template).length) {
                    return compile(document.querySelector(template).innerHTML.trim());
                }
                else {
                    return compile(template);
                }
            }
            catch (error) {
                return compile(template);
            }
        }
        return undefined;
    }
    getNavigatorState(target, isPrevious) {
        const button = target.querySelector(`.${isPrevious ? CLS_PREV_BUTTON : CLS_NEXT_BUTTON}`);
        if (button) {
            const buttonObj = getInstance(button, Button);
            return buttonObj.disabled;
        }
        return false;
    }
    navigatorClickHandler(e) {
        const target = e.currentTarget;
        const isDisabled = this.getNavigatorState(target, target.classList.contains(CLS_PREVIOUS));
        if (isDisabled) {
            return;
        }
        const direction = target.classList.contains(CLS_PREVIOUS) ? 'Previous' : 'Next';
        this.setActiveSlide(this.getSlideIndex(direction), direction);
        this.autoSlide();
    }
    indicatorClickHandler(e) {
        const target = closest(e.target, `.${CLS_INDICATOR_BAR}`);
        const index = parseInt(target.dataset.index, 10);
        if (this.selectedIndex !== index) {
            this.setActiveSlide(index, this.selectedIndex > index ? 'Previous' : 'Next');
            this.autoSlide();
        }
    }
    playButtonClickHandler(e, isPropertyChange = false) {
        const playButton = this.element.querySelector(`.${CLS_PLAY_BUTTON}`);
        if (playButton) {
            const buttonObj = getInstance(playButton, Button);
            if (!isPropertyChange) {
                this.setProperties({ autoPlay: !this.autoPlay }, true);
            }
            playButton.setAttribute('aria-label', this.localeObj.getConstant(this.autoPlay ? 'pauseSlideTransition' : 'playSlideTransition'));
            buttonObj.iconCss = CLS_ICON + ' ' + (this.autoPlay ? CLS_PAUSE_ICON : CLS_PLAY_ICON);
            buttonObj.dataBind();
            const itemsContainer = this.element.querySelector(`.${CLS_ITEMS}`);
            itemsContainer.setAttribute('aria-live', this.autoPlay ? 'off' : 'polite');
            if (this.autoPlay && !this.loop && this.selectedIndex === this.slideItems.length - 1) {
                this.setActiveSlide(0, 'Next');
            }
            this.autoSlide();
        }
    }
    keyHandler(e) {
        let direction;
        let slideIndex;
        let isSlideTransition = false;
        const target = e.target;
        e.preventDefault();
        switch (e.action) {
            case 'space':
                if (this.showIndicators && target.classList.contains(CLS_INDICATOR)) {
                    target.click();
                }
                else if (target.classList.contains(CLS_CAROUSEL) || target.classList.contains(CLS_PLAY_BUTTON)) {
                    this.playButtonClickHandler(e);
                }
                else if (target.classList.contains(CLS_NEXT_BUTTON)) {
                    this.next();
                }
                else if (target.classList.contains(CLS_PREV_BUTTON)) {
                    this.prev();
                }
                break;
            case 'end':
                slideIndex = this.slideItems.length - 1;
                direction = 'Next';
                isSlideTransition = true;
                break;
            case 'home':
                slideIndex = 0;
                direction = 'Previous';
                isSlideTransition = true;
                break;
            case 'moveUp':
            case 'moveLeft':
            case 'moveDown':
            case 'moveRight':
                if (this.showIndicators && isNullOrUndefined(this.indicatorsTemplate)) {
                    this.element.focus();
                }
                direction = (e.action === 'moveUp' || e.action === 'moveLeft') ? 'Previous' : 'Next';
                slideIndex = this.getSlideIndex(direction);
                isSlideTransition = !this.isSuspendSlideTransition(slideIndex, direction);
                break;
        }
        if (isSlideTransition) {
            this.setActiveSlide(slideIndex, direction);
            this.autoSlide();
            isSlideTransition = false;
        }
    }
    swipeHandler(e) {
        if (this.element.classList.contains(CLS_HOVER)) {
            return;
        }
        const direction = (e.swipeDirection === 'Right') ? 'Previous' : 'Next';
        const slideIndex = this.getSlideIndex(direction);
        if (!this.isSuspendSlideTransition(slideIndex, direction)) {
            this.setActiveSlide(slideIndex, direction, true);
            this.autoSlide();
        }
    }
    isSuspendSlideTransition(index, direction) {
        return !this.loop && (direction === 'Next' && index === 0 || direction === 'Previous' && index === this.slideItems.length - 1);
    }
    handleNavigatorsActions(index) {
        if (this.buttonsVisibility === 'Hidden') {
            return;
        }
        if (this.showPlayButton) {
            const playButton = this.element.querySelector(`.${CLS_PLAY_BUTTON}`);
            const isLastSlide = this.selectedIndex === this.slideItems.length - 1 && !this.loop;
            let isButtonUpdate = isNullOrUndefined(this.playButtonTemplate) && playButton && isLastSlide;
            if (isNullOrUndefined(this.playButtonTemplate) && playButton && !isLastSlide) {
                isButtonUpdate = !playButton.classList.contains(CLS_ACTIVE);
            }
            if (isButtonUpdate) {
                this.setProperties({ autoPlay: !isLastSlide }, true);
                playButton.setAttribute('aria-label', this.localeObj.getConstant(this.autoPlay ? 'pauseSlideTransition' : 'playSlideTransition'));
                const itemsContainer = this.element.querySelector(`.${CLS_ITEMS}`);
                itemsContainer.setAttribute('aria-live', this.autoPlay ? 'off' : 'polite');
                const buttonObj = getInstance(playButton, Button);
                buttonObj.iconCss = CLS_ICON + ' ' + (this.autoPlay ? CLS_PAUSE_ICON : CLS_PLAY_ICON);
                buttonObj.dataBind();
            }
        }
        const prevButton = this.element.querySelector(`.${CLS_PREV_BUTTON}`);
        if (prevButton && isNullOrUndefined(this.previousButtonTemplate)) {
            const buttonObj = getInstance(prevButton, Button);
            buttonObj.disabled = !this.loop && index === 0;
            buttonObj.dataBind();
        }
        const nextButton = this.element.querySelector(`.${CLS_NEXT_BUTTON}`);
        if (nextButton && isNullOrUndefined(this.nextButtonTemplate)) {
            const buttonObj = getInstance(nextButton, Button);
            buttonObj.disabled = !this.loop && index === this.slideItems.length - 1;
            buttonObj.dataBind();
        }
    }
    onHoverActions(e) {
        const navigator = this.element.querySelector(`.${CLS_NAVIGATORS}`);
        switch (e.type) {
            case 'mouseenter':
                if (this.buttonsVisibility === 'VisibleOnHover' && navigator) {
                    removeClass([].slice.call(navigator.childNodes), CLS_HOVER_ARROWS);
                }
                if (this.pauseOnHover) {
                    addClass([this.element], CLS_HOVER);
                }
                break;
            case 'mouseleave':
                if (this.buttonsVisibility === 'VisibleOnHover' && navigator) {
                    addClass([].slice.call(navigator.childNodes), CLS_HOVER_ARROWS);
                }
                removeClass([this.element], CLS_HOVER);
                break;
        }
        this.autoSlide();
    }
    onFocusActions(e) {
        switch (e.type) {
            case 'focusin':
                addClass([this.element], CLS_HOVER);
                break;
            case 'focusout':
                removeClass([this.element], CLS_HOVER);
                break;
        }
        this.autoSlide();
    }
    destroyButtons() {
        const buttonCollections = [].slice.call(this.element.querySelectorAll('.e-control.e-btn'));
        for (const button of buttonCollections) {
            const instance = getInstance(button, Button);
            if (instance) {
                instance.destroy();
            }
        }
    }
    wireEvents() {
        EventHandler.add(this.element, 'focusin focusout', this.onFocusActions, this);
        EventHandler.add(this.element, 'mouseenter mouseleave', this.onHoverActions, this);
        EventHandler.add(this.element.firstElementChild, 'animationend', this.onTransitionEnd, this);
        EventHandler.add(this.element.firstElementChild, 'transitionend', this.onTransitionEnd, this);
    }
    unWireEvents() {
        const indicators = [].slice.call(this.element.querySelectorAll(`.${CLS_INDICATOR_BAR}`));
        indicators.forEach((indicator) => {
            EventHandler.remove(indicator, 'click', this.indicatorClickHandler);
        });
        const navigators = [].slice.call(this.element.querySelectorAll(`.${CLS_PREVIOUS},.${CLS_NEXT}`));
        navigators.forEach((navigator) => {
            EventHandler.remove(navigator, 'click', this.navigatorClickHandler);
        });
        const playIcon = this.element.querySelector(`.${CLS_PLAY_PAUSE}`);
        if (playIcon) {
            EventHandler.remove(playIcon, 'click', this.playButtonClickHandler);
        }
        EventHandler.remove(this.element.firstElementChild, 'animationend', this.onTransitionEnd);
        EventHandler.remove(this.element.firstElementChild, 'transitionend', this.onTransitionEnd);
        EventHandler.clearEvents(this.element);
    }
    /**
     * Method to transit from the current slide to the previous slide.
     *
     * @returns {void}
     */
    prev() {
        if (!this.loop && this.selectedIndex === 0) {
            return;
        }
        const index = (this.selectedIndex === 0) ? this.slideItems.length - 1 : this.selectedIndex - 1;
        this.setActiveSlide(index, 'Previous');
        this.autoSlide();
    }
    /**
     * Method to transit from the current slide to the next slide.
     *
     * @returns {void}
     */
    next() {
        if (!this.loop && this.selectedIndex === this.slideItems.length - 1) {
            return;
        }
        const index = (this.selectedIndex === this.slideItems.length - 1) ? 0 : this.selectedIndex + 1;
        this.setActiveSlide(index, 'Next');
        this.autoSlide();
    }
    /**
     * Method to play the slides programmatically.
     *
     * @returns {void}
     */
    play() {
        const playIcon = this.element.querySelector(`.${CLS_PLAY_ICON}`);
        if (this.showPlayButton && playIcon) {
            classList(playIcon, [CLS_PAUSE_ICON], [CLS_PLAY_ICON]);
            const playButton = this.element.querySelector(`.${CLS_PLAY_BUTTON}`);
            playButton.setAttribute('aria-label', this.localeObj.getConstant('pauseSlideTransition'));
        }
        this.setProperties({ autoPlay: true }, true);
        const itemsContainer = this.element.querySelector(`.${CLS_ITEMS}`);
        itemsContainer.setAttribute('aria-live', 'off');
        this.applySlideInterval();
    }
    /**
     * Method to pause the slides programmatically.
     *
     * @returns {void}
     */
    pause() {
        const pauseIcon = this.element.querySelector(`.${CLS_PAUSE_ICON}`);
        if (this.showPlayButton && pauseIcon) {
            const playButton = this.element.querySelector(`.${CLS_PLAY_BUTTON}`);
            playButton.setAttribute('aria-label', this.localeObj.getConstant('playSlideTransition'));
            classList(pauseIcon, [CLS_PLAY_ICON], [CLS_PAUSE_ICON]);
        }
        this.setProperties({ autoPlay: false }, true);
        const itemsContainer = this.element.querySelector(`.${CLS_ITEMS}`);
        itemsContainer.setAttribute('aria-live', 'off');
        this.resetSlideInterval();
    }
    /**
     * Method to render react and angular templates
     *
     * @returns {void}
     * @private
     */
    renderTemplates() {
        if (this.isAngular || this.isReact) {
            this.renderReactTemplates();
        }
    }
    /**
     * Method to reset react and angular templates
     *
     * @param {string[]} templates Accepts the template ID
     * @returns {void}
     * @private
     */
    resetTemplates(templates) {
        if (this.isAngular || this.isReact) {
            this.clearTemplate(templates);
        }
    }
    /**
     * Method for destroy the carousel component.
     *
     * @returns {void}
     */
    destroy() {
        this.resetTemplates();
        if (this.touchModule) {
            this.touchModule.destroy();
            this.touchModule = null;
        }
        this.keyModule.destroy();
        this.keyModule = null;
        this.resetSlideInterval();
        this.destroyButtons();
        this.unWireEvents();
        [].slice.call(this.element.children).forEach((ele) => { this.element.removeChild(ele); });
        removeClass([this.element], [CLS_CAROUSEL, this.cssClass, CLS_RTL]);
        ['tabindex', 'role', 'style'].forEach((attr) => { this.element.removeAttribute(attr); });
        super.destroy();
    }
};
__decorate([
    Collection([], CarouselItem)
], Carousel.prototype, "items", void 0);
__decorate([
    Property('Slide')
], Carousel.prototype, "animationEffect", void 0);
__decorate([
    Property()
], Carousel.prototype, "previousButtonTemplate", void 0);
__decorate([
    Property()
], Carousel.prototype, "nextButtonTemplate", void 0);
__decorate([
    Property()
], Carousel.prototype, "indicatorsTemplate", void 0);
__decorate([
    Property()
], Carousel.prototype, "playButtonTemplate", void 0);
__decorate([
    Property()
], Carousel.prototype, "cssClass", void 0);
__decorate([
    Property([])
], Carousel.prototype, "dataSource", void 0);
__decorate([
    Property()
], Carousel.prototype, "itemTemplate", void 0);
__decorate([
    Property(0)
], Carousel.prototype, "selectedIndex", void 0);
__decorate([
    Property('100%')
], Carousel.prototype, "width", void 0);
__decorate([
    Property('100%')
], Carousel.prototype, "height", void 0);
__decorate([
    Property(5000)
], Carousel.prototype, "interval", void 0);
__decorate([
    Property(true)
], Carousel.prototype, "autoPlay", void 0);
__decorate([
    Property(true)
], Carousel.prototype, "pauseOnHover", void 0);
__decorate([
    Property(true)
], Carousel.prototype, "loop", void 0);
__decorate([
    Property(false)
], Carousel.prototype, "showPlayButton", void 0);
__decorate([
    Property(true)
], Carousel.prototype, "enableTouchSwipe", void 0);
__decorate([
    Property(true)
], Carousel.prototype, "showIndicators", void 0);
__decorate([
    Property('Visible')
], Carousel.prototype, "buttonsVisibility", void 0);
__decorate([
    Property()
], Carousel.prototype, "htmlAttributes", void 0);
__decorate([
    Event()
], Carousel.prototype, "slideChanging", void 0);
__decorate([
    Event()
], Carousel.prototype, "slideChanged", void 0);
Carousel = __decorate([
    NotifyPropertyChanges
], Carousel);
export { Carousel };
